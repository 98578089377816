import ApiService from "@/core/services/ApiService"
import Swal from "sweetalert2/dist/sweetalert2.js"

export default class UserConfigService {
  static async update(val, userConfigs) {
    ApiService.setHeader()
    const fail: any[] = []
    const pass: any[] = []
    let response

    for (const u of userConfigs) {

      try {
        await ApiService.putData(`/user-configs/${u.id}`, { "subscribe_monthly_email": val })
        pass.push(u.id)
      } catch (e) {
        console.log(e.message)
        fail.push(u.id)
      }
    }

    response = pass.length ? `Successfully updated ${pass.join(", ")}` : ""
    response += fail.length && pass.length ? ` and unsuccessful for ${fail.join(", ")}` :
      fail.length ? ` Unsuccessfully updated ${fail.join(", ")}` : ""

    Swal.fire({
      text: response,
      icon: "info",
      confirmButtonText: "Ok",
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-light-primary"
      }
    })
  }
}

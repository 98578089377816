import ApiService from "@/core/services/ApiService"
import store from "@/store"

export default class UsersService {
  static accountNum = store.getters.activeAccountInfo.id

  static async getUsers() {
    ApiService.setHeader()
    let resp
    try {
      resp = await ApiService.get(`/accounts/${ this.accountNum }/users`)
    } catch (e) {
      console.error("Problem fetching account users", e)
    }
    if (resp) {
      return resp.data.data.meta.users
    }

    return false
  }
}


import { defineComponent, ref } from "vue"
import * as Yup from "yup"
import { ErrorMessage, Field, Form } from "vee-validate"
import Swal from "sweetalert2/dist/sweetalert2.js"
import ApiService from "@/core/services/ApiService"
import { Modal } from "bootstrap"
import { User } from "@/core/interfaces/user"
import Dropzone from "@/components/Dropzone.vue"
import store from "@/store"
import axios from "axios"

export default defineComponent({
  name: "addUser",
  components: {
    ErrorMessage,
    Field,
    Form,
    Dropzone
  },
  emits: ["reloadTable"],
  setup() {
    const addUser = ref<User>({
      first_name: "",
      last_name: "",
      email: "",
      mobile_phone: "",
      status: "active",
      avatar_url: ""
    })

    const initAddUser = () => {
      addUser.value.first_name = ""
      addUser.value.last_name = ""
      addUser.value.email = ""
      addUser.value.mobile_phone = ""
      addUser.value.avatar_url = ""
    }

    const addUserFormValidator = Yup.object().shape({
      email: Yup.string().required().email().label("Email"),
      first_name: Yup.string().label("First Name"),
      last_name: Yup.string().label("Last Name"),
      mobile_phone: Yup.string()
        .label("Phone Number")
    })

    const submitAddUserRequest = ref<HTMLElement | null>(null)
    let avatar = ""

    return {
      addUserFormValidator,
      submitAddUserRequest,
      initAddUser,
      addUser,
      avatar
    }
  },
  methods: {
    closeModal() {
      const modalElement = document.getElementById("addNewUser")
      const modalInstance = Modal.getInstance(modalElement)
      modalInstance.hide()

      // Hacky fix for bootstrap modal's backdrop persisting
      document.querySelector(".modal-backdrop")?.remove()
    },
    async sendRequest() {

      const ax = axios.create({
        baseURL: process.env.VUE_APP_FOOTPRINT_API_ENDPOINT || "https://footprint-api.hlmtech.com",
        headers: { "Content-Type": this.avatar["type"] }
      })

      ApiService.setHeader()
      let addUserResponse, addAvatarResponse
      try {

        addUserResponse = await ApiService.post("/users", {
          data: {
            accountNum: store.getters.activeAccountInfo.id,
            form: this.addUser
          }
        })

      } catch (e) {
        console.error("Problem fetching add user response", e)
      }

      if (addUserResponse.status == 201 || addUserResponse.status == 200) {

        let userId = addUserResponse.data.data.id

        if(this.avatar != "") {
          addAvatarResponse = await ax.put(`/users/${ userId }/data/avatar`, this.avatar)
        }


        if(addAvatarResponse && addAvatarResponse.status == 200) {
          return {status: addUserResponse.status, message: "With Avatar"}
        } else if(!addAvatarResponse) {
          return {status: addUserResponse.status, message: "Without Avatar"}
        } else {
          return {message: "error adding avatar"}
        }

      } else {
        return {message: "error creating user"}
      }
    },
    async saveUser() {
      if (this.submitAddUserRequest) {
        // Activate indicator
        this.submitAddUserRequest.setAttribute("data-kt-indicator", "on")

        const response = await this.sendRequest()
        let choice

        if (response.status == 200 || response.status == 201) {
          choice = await Swal.fire({
            text: response.status == 200 ? `User Added to Account ${response.message}!` : `New User Created ${response.message}!`,
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
        } else {
          choice = await Swal.fire({
            text: response.message,
            icon: "error",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
        }

        // cleanup / reload things
        if (choice) {
          this.submitAddUserRequest.removeAttribute("data-kt-indicator")
          this.initAddUser()
          this.closeModal()
          this.$emit("reloadTable")
        }

      }
    },
    fileAdded(val) {
      if (val.upload.filename.match("^.*.(jpg|JPG|png|PNG)$")) {
        console.log("added: ", val.upload.filename)
      } else {
        Swal.fire({
          text: "Please upload a .jpg or .png file",
          icon: "error",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary"
          }
        })
        return
      }
      this.avatar = val
    },
    fileRemoved(val) {
      console.log("removed: ", val.upload.filename)
      this.avatar = ""
    },
  }
})


import { defineComponent, ref } from "vue"
import UsersTable from "@/components/UsersTable.vue"
import AddUser from "@/components/modals/CreateUserModal.vue"
import { Modal } from "bootstrap"

export default defineComponent({
  name: "users",
  components: {
    UsersTable,
    AddUser
  },
  emits: ["throwError"],
  data() {
    let forceReload = ref(false).value

    return {
      key: 0,
      forceReload
    }
  },
  methods: {
    async reloadTable() {
      this.key++
      this.forceReload = !this.forceReload
    },
    openAddUserModal() {
      const modalElement = document.getElementById("addNewUser")
      const modalInstance = Modal.getOrCreateInstance(modalElement)
      modalInstance.show()
    }
  }
});

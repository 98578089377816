
import { defineComponent, ref } from "vue"
import * as Yup from "yup"
import { ErrorMessage, Field, Form } from "vee-validate"
import Swal from "sweetalert2/dist/sweetalert2.js"
import ApiService from "@/core/services/ApiService"
import { Modal } from "bootstrap"
import { User } from "@/core/interfaces/user"
import Dropzone from "@/components/Dropzone.vue"
import axios from "axios"

export default defineComponent({
  name: "Edit User Modal",
  props: {
    user: {
      type: Object,
      required: true
    },
    modalId: String,
  },
  emits: ["reloadTable"],
  components: {
    ErrorMessage,
    Field,
    Form,
    Dropzone
  },
  setup(props) {
    const submitEditUserRequest = ref<HTMLElement | null>(null)

    const closeModal = (id) => {
      // close open editUserModal
      const modalId = String('id_' + id)
      const modalElement = document.getElementById(modalId)
      const modalInstance = Modal.getInstance(modalElement)
      modalInstance.hide()
    }

    const editUserFormValidator = Yup.object().shape({
      email: Yup.string().required().label("email"),
      first_name: Yup.string().label("First Name"),
      last_name: Yup.string().label("Last Name"),
      mobile_phone: Yup.string().label("Phone Number")
    })

    let avatar = ""

    return {
      closeModal,
      submitEditUserRequest,
      editUserFormValidator,
      editUser: ref(props.user).value as User,
      avatar
    }
  },
  methods: {
    async deleteAvatar(id) {
      console.log('delete user ' + id + '\'s avatar')
      this.editUser.avatar_url = ""
      let choice

      choice = await Swal.fire({
        text: `Are you sure you want to delete user ${ id }'s avatar?`,
        icon: "question",
        confirmButtonText: "Ok",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-light-primary"
        }
      })

      if (choice.isConfirmed) {
        // remove avatar
        // update table
        const ax = axios.create({
          baseURL: process.env.VUE_APP_FOOTPRINT_API_ENDPOINT || "https://footprint-api.hlmtech.com",
          headers: { "Content-Type": "application/json" }
        })

        let avatarDelete
        try {
          avatarDelete = await ax.put(`/users/${ id }/data/avatar`, {})
        } catch (e) {
          console.error("Problem uploading account logo", e)
        }
        if (avatarDelete.status == 200) {
          this.avatar = ""
          this.closeModal(id)
          this.$emit("reloadTable")

          await Swal.fire({
            text: `Avatar deleted successfully!`,
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })

        }

      }
    },
    async sendRequest() {

      const ax = axios.create({
        baseURL: process.env.VUE_APP_FOOTPRINT_API_ENDPOINT || "https://footprint-api.hlmtech.com",
        headers: { "Content-Type": this.avatar["type"] }
      })

      ApiService.setHeader()

      let updateCustomResponse, addAvatarResponse
      try {
        updateCustomResponse = await ApiService.put(`/users/${ this.editUser.id }`, { data: this.editUser })
      } catch (e) {
        console.error("Problem fetching update user response", e)
        return { message: "error updating user" }
      }

      if (updateCustomResponse.data) {

        if (this.avatar != "") {
          addAvatarResponse = await ax.put(`/users/${ this.editUser.id }/data/avatar`, this.avatar)
        }
        if (addAvatarResponse && addAvatarResponse.status == 200) {
          return { status: updateCustomResponse.status, message: "with avatar" }
        } else if (!addAvatarResponse) {
          return { status: updateCustomResponse.status, message: "without avatar" }
        } else {
          return { message: "error updating avatar" }
        }

      }

      return { message: "error updating user" }
    },
    async updateUser() {
      if (this.submitEditUserRequest) {
        // Activate indicator
        this.submitEditUserRequest.setAttribute("data-kt-indicator", "on")

        let response = await this.sendRequest()
        let choice
        if (response.status == 200) {
          choice = await Swal.fire({
            text: `The user was updated successfully ${ response.message }!`,
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.submitEditUserRequest.removeAttribute("data-kt-indicator")
          this.closeModal(this.editUser.id)
        } else {
          choice = await Swal.fire({
            text: response.message,
            icon: "error",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.submitEditUserRequest.removeAttribute("data-kt-indicator")
          this.closeModal(this.editUser.id)
        }

        // reload table with new or init data after modal close
        if (choice) {
          this.$emit("reloadTable")
        }

      }
    },
    fileAdded(val) {
      if (val.upload.filename.match("^.*.(jpg|JPG|png|PNG)$")) {
        console.log("added: ", val.upload.filename)
      } else {
        Swal.fire({
          text: "Please upload a .jpg or .png file",
          icon: "error",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary"
          }
        })
        return
      }
      this.avatar = val
    },
    fileRemoved(val) {
      console.log("removed: ", val.upload.filename)
      this.avatar = ""
    },
  }
});


import { defineComponent, ref } from "vue"
import UserService from "@/core/services/UserService"
import { Modal } from "bootstrap"
import EditUser from "@/components/modals/EditUserModal.vue"
import ApiService from "@/core/services/ApiService"
import Swal from "sweetalert2/dist/sweetalert2.js"
import store from "@/store"
import _ from "lodash"
import UserConfigService from "@/core/services/UserConfigService"
import { UserConfig } from "@/core/interfaces/userConfig"
import AccountService from "@/core/services/AccountService";

export type User = {
  [key: string]: any;
}

export default defineComponent({
  name: "SEORankingsTable",
  components: {
    EditUser
  },
  emits: ["throwError"],
  props: {
    forceReload: Boolean
  },
  data() {
    const accountNum = store.getters.activeAccountInfo.id
    const accountUsers = store.getters.activeAccountInfo.details.users
    return {
      accountUsers,
      accountNum,
      loading: ref(true),
      users: ref<User[]>(),
      userConfigs: [] as UserConfig[],
    }
  },
  async created() {
    await this.setupUsers()
  },
  methods: {
    async setupUsers() {
      this.userConfigs = (await AccountService.getAccountUserConfigs(this.accountNum)).map((us) => {
        return { id: us.id, ...us.attributes }
      })

      this.users = await UserService.getUsers()
      if (this.users) {
        this.loading = false

        _.map(this.users, ((user) => {
          user.config_id = _.result(_.find(this.userConfigs, (uCnfg) => {
            return uCnfg.email == user.email
          }), 'id')
          user.monthly_email = _.result(_.find(this.userConfigs, (uCnfg) => {
            return uCnfg.email == user.email
          }), 'subscribe_monthly_email')
        }))

      }
    },

    async userAction(e, id: string, config_id: string) {
      const action = e.target.value
      if (action == "inactivate") {
        await this.inactivate(id, config_id)
      } else if (action == "send-email") {
        this.accessEmail(id)
      } else if (action == "edit") {
        const modalId = String('id_' + id)
        const modalElement = document.getElementById(modalId)
        const modalInstance = Modal.getOrCreateInstance(modalElement)
        modalInstance.show()
      }
      e.target.value = "default"
    },

    async emailPreferences(e, id: string) {
      const val = e
      const user_id = id
      console.log(`update user ${user_id} monthly email preference to ${val}`)

      let option = val ? "enable" : "disable"
      let action = await Swal.fire({
        text: `Are you sure you want to ${option} user ${user_id}?`,
        icon: "question",
        confirmButtonText: "Ok",
        showCancelButton: true,
        buttonsStyling: false,
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-light-primary",
          cancelButton: "btn btn-danger me-3"
        }
      })

      if (action) {
        if (action.isConfirmed && option == "disable") {
          await UserConfigService.update(false, [_.find(this.userConfigs, ((uCnfg) => uCnfg.sk.includes(user_id)))])
          await this.reloadTable()
        } else if (action.isConfirmed && option == "enable") {
          await UserConfigService.update(true, [_.find(this.userConfigs, ((uCnfg) => uCnfg.sk.includes(user_id)))])
          await this.reloadTable()
        }
      }
    },

    async inactivate(id, config_id) {
      let choice = await Swal.fire({
        text: `Are you sure you want to remove the user from this account?`,
        icon: "question",
        confirmButtonText: "Ok",
        showCancelButton: true,
        buttonsStyling: false,
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-light-primary",
          cancelButton: "btn btn-danger me-3"
        }
      })

      // reload table with new or init data after modal close
      if (choice.isConfirmed) {
        _.remove(this.accountUsers, (o) => o == id)

        ApiService.setHeader()

        let updateCustomResponse
        try {
          updateCustomResponse = await ApiService.put(`/accounts/${this.accountNum}`, { data: { "users": this.accountUsers } })
        } catch (e) {
          console.error("Problem setting user status", e)
        }

        try {
          await ApiService.delete('/accounts/' + this.accountNum + '/user-configs/' + config_id)
        } catch (e) {
          console.error("Problem removing user config", e)
        }

        if (updateCustomResponse.status == 200) {
          await this.reloadTable()
        }
      }

    },
    accessEmail(id) {
      // @TODO hit endpoint to send user access email
      console.log(`perform send user access email action on user ${id} to come soon`)
    },

    statusText(status) {
      status = typeof status === "string" ? status.toLowerCase() : status
      switch (status) {
        case 0:
        case "inactive":
          return "Inactive"
        case 1:
        case "active":
          return "Active"
        case 21:
        case "archived":
          return "Archived"
        default:
          break
      }
    },
    async reloadTable() {
      this.loading = true
      this.users = []
      await this.setupUsers()
      this.loading = false
    },
  },
  watch: {
    forceReload: {
      deep: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.reloadTable()
        }
      }
    }
  }
});
